<template>
  <v-container>
    <v-card>
      <v-form @submit.prevent="submit">
        <v-card-title>TopLogger mail tester</v-card-title>
        <v-card-text>
          <v-text-field v-model="formData.email" label="Mail to" type="email" />
          <v-text-field v-model="formData.subject" label="Subject" />
          <v-textarea v-model="formData.body" label="Message" />
        </v-card-text>
        <v-card-actions>
          <v-btn text type="submit" color="primary">Submit</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import store from '@/store'
import axios from '@/services/axios'
import errorService from '@/services/error-service'

export default {
  data: () => ({
    formData: {},
  }),
  beforeRouteEnter(to, from, next) {
    if (!store.state.user.admin) return next(false)
    next()
  },
  methods: {
    submit() {
      axios.post(`/bezorg`, this.formData).catch(errorService.toast)
    },
  },
}
</script>
